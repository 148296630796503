$block: '.wrapper';

#{$block} {
    min-height: 100%;
}

#{$block}__demo-logo-vertical {
    height: 32px;
    margin: 16px;
    background: rgba(255, 255, 255, .2);
    border-radius: 6px;
    text-align: center;
    vertical-align: middle;
    line-height: 32px;
    color: rgba(256,256,256,0.65);
    font-weight: 700;
}